<template>
  <nuxt-link
    class="ArticleListingCard"
    :to="$sbLinkFix(story.full_slug)"
  >
    <div class="relative">
      <nuxt-img
        v-if="story.content?.summaryImage?.filename"
        preset="standard"
        loading="lazy"
        sizes="sm:100vw desk:404px"
        :src="story.content.summaryImage.filename"
        :alt="story.content.summaryTitle"
        class="w-full mb-12 object-cover aspect-wide"
      />
      <div
        v-if="story.content.summaryTime"
        :class="summaryTime.class"
      >
        <img
          src="/icons/play-filled-inv.svg"
          class="w-8 h-8 desk:w-12 desk:h-12"
        >
        <div class="pt-2"> {{ story.content.summaryTime }} </div>
      </div>
    </div>
    <div class="px-12">
      <div
        v-if="story.content.summaryCategories"
        class="type-headline-xxs desk:type-headline-xs underline decoration-darkest/20 mb-4 desk:mb-12"
      >
        {{ $t(`articles.keyword.${story.content.summaryCategories}`) }}
      </div>
      <div class="font-medium">{{ story.content.summaryTitle }}</div>
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
import { ArticlePage } from '~/constants/types/storyblok';

const props = defineProps<{
  story: ArticlePage,
}>();

const summaryTime = computed(() => ({
  class: 'absolute bottom-12 left-12 pr-8 desk:pr-12 pl-4 h-20 desk:h-24 flex justify-center items-center gap-6 desk:gap-8 bg-darkest text-lightest type-headline-xxs desk:type-headline-xs opacity-90',
}));

</script>

<style scoped lang="postcss">
</style>
